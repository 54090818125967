import { Tab } from "./types/tab";

export const isProduction = window.location.hostname === "watchbill.socraft.ch";
export const isQa = window.location.hostname === "qa.my.watchbill.app";

export const watchBillApiBaseUrl: string =
  !isProduction && !isQa ? "http://localhost:8000/api" : "/api";

export const auth0Config = {
  domain: "watchbill.eu.auth0.com",
  clientId: "sRR72OkS8YEWb3UO6J3KYlxGjrwhiPz7",
  audience: "https://my.watchbill.app/api",
};

export const tabs: Tab[] = [
  {
    path: "/",
    id: "home",
  },
  {
    path: "/contacts",
    id: "contacts",
    neededRoles: ["supplier"],
  },
  {
    path: "/settings",
    id: "settings",
    neededRoles: ["supplier"],
  },
];
